<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Our Branch</h1>
      </div>
    </div>
    <div class="md:mx-20 md:my-16 m-8 flex flex-col sm:gap-16 gap-12">
      <!-- Image -->
      <div class="md:h-562 h-64 relative">
        <img
          src="../assets/peta_cabang_tripwe.png"
          alt=""
          class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
        />
      </div>

      <div class="grid sm:grid-cols-2 grid-cols-1 gap-8 sm:mx-8 mb-8">
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Jakarta</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Marina
              Ancol Dermaga 17, Jakarta
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:081938358888" class="text-green-1 hover:underline"
                >0819 3835 8888</a
              >
              /
              <a href="tel:081295061988" class="text-green-1 hover:underline"
                >0812 9506 1988</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:sales@jetskisafarijakarta.com"
                class="text-green-1 hover:underline"
                >sales@jetskisafarijakarta.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/jakarta"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/jakarta</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Semarang</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : JL. Villa
              Marina, Kota Semarang, Jawa Tengah
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:08112717778" class="text-green-1 hover:underline"
                >0811 271 7778</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafarisemarang01@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafarisemarang01@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/semarang"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/semarang</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Anyer</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl. Raya
              Anyer-Sirih No.136, Sindanglaya, Kec. Cinangka, Serang, Banten
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:0817228884" class="text-green-1 hover:underline"
                >081 722 8884</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskikadenaanyer@gmail.com"
                class="text-green-1 hover:underline"
                >jetskikadenaanyer@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/anyer"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/anyer</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Bali Wibisana</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl
              Pratama, Margi Pemuunan, Tanjung Benoa, Bali
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:082146499901" class="text-green-1 hover:underline"
                >0821 4649 9901</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafaribwibisana@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafaribwibisana@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/bali-wibisana"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/bali-wibisana</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Makassar</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl. Ujung
              Pandang No.4, Bulo Gading, Kec. Ujung Pandang, Kota Makassar,
              Sulawesi Selatan
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:081325288862" class="text-green-1 hover:underline"
                >0813 2528 8862</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafarimakassarr@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafarimakassarr@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/makassar"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/makassar</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Batam</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl Trans
              Barelang, Harris Resort Barelang, Batam
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:08119787311" class="text-green-1 hover:underline"
                >0811 9787 311</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafaribatam@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafaribatam@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/batam"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/batam</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Jepara</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> :
              Tegalsambi, Kec. Tahunan, Kab. Jepara, Jawa Tengah
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:089671765343" class="text-green-1 hover:underline"
                >0896 7176 5343</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:info@oceanview-view.com"
                class="text-green-1 hover:underline"
                >info@oceanview-view.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/jepara"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/jepara</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Bali Amarta</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl. Kusuma
              Sari, Sanur, Bali
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:08123961020" class="text-green-1 hover:underline"
                >0812 396 1020</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafari.baliamarta@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafari.baliamarta@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/bali-amarta"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/bali-amarta</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Balikpapan</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : Jl
              Jenderal No 1, Klandasan Ilir, Balikpapan
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:082148684801" class="text-green-1 hover:underline"
                >0821 4868 4801</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafaribpn01@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafaribpn01@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/balikpapan"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/balikpapan</a
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold">Jet Ski Safari</h3>
          <h3 class="font-bold">Belitung</h3>
          <div class="flex flex-col gap-2 text-gray-600">
            <p>
              <font-awesome-icon icon="fa-solid fa-location-dot" /> : JL. Tj.
              Pandan – Tj. Kelayang, Keciput, Tj. Pandan, Kab. Belitung,
              Kepulauan Bangka Belitung
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-phone" /> :
              <a href="tel:08117311887" class="text-green-1 hover:underline"
                >0811 7311 887</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-solid fa-at" /> :
              <a
                href="mailto:jetskisafari.belitung@gmail.com"
                class="text-green-1 hover:underline"
                >jetskisafari.belitung@gmail.com</a
              >
            </p>
            <p>
              <font-awesome-icon icon="fa-sharp fa-solid fa-map-location-dot" />
              :
              <a
                href="https://maps.tripwe.com/belitung"
                class="text-green-1 hover:underline"
                >maps.tripwe.com/belitung</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: { Header, Footer },
};
</script>
